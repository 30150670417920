.container{
	margin-top: 100px;
}

h1{
	font-size: 64px;
	line-height: 150%;
}

h3{
	font-size: 30px;
}

h1,h2,h3,h4,h5,h6,p,a,div,span{
	font-family: 'Open Sans';
}