.table {
  margin-top: 20px;
  table-layout: fixed;
  
}
td{
  width: 15vw;
}

.DataTable img {
  max-width: 50px;
}

.DataTable.image {
  margin-right: 30px;
}

.dataTableIcon {
  border: none;
  background-color: transparent;
  outline: none;
}

.DataTableIconContainer {
  text-align: right;
  margin-top: 40px;
}

.DataTableIconContainer.bottom {
  margin-top: 20px;
  margin-bottom: 100px;
}

.DataTable .inline {
  display: inline-block;
  vertical-align: top;
}

.DataTable p {
  margin-bottom: 0;
}

.DataTable th {
  font-size: 15px;
}

.DataTable .center {
  text-align: center;
}

.DataTable .info {
  padding: 2px 4px 2px 4px;
  font-size: 9px;
  color: rgb(255, 255, 255);
  margin-bottom: 5px;
}

.DataTable .info.red {
  background: red;
}

.DataTable .info.blue {
  background: blue;
}

.DataTable .info.deprecated {
  background: red;
}
.DataTable .info.lend {
  background: blue;
}
.DataTable .info.borrow {
  background: red;
}

.DataTable .info.loan {
  background: blue;
}

.DataTable .smallImage {
  max-width: 25px;
  margin-top: -5px;
  display: block;
  position: relative;
  left: 45%;
  transform: translate(-50%, -50%);
}


.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.filterchain {
  flex: 1 1 0;
  margin-top: 8px;
  margin-right: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  background: white;
  border-radius: 5px;
  border: 1px solid var(--separator-color);
  background-color: var(--back-vlight);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 16px 16px;
  padding: 9px 9px 9px 31px;
  font-size: 12px;
  font-weight: 600;
  color: var(--text-white);
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.rmsc{
  margin-left: 13px !important;
  width: 70% !important;
}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 80% !important;
}
}
